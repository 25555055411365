import { AutocompleteRenderInputParams, SxProps } from '@mui/material';
import { TextField, TextFieldProps } from '@portals/core/src/components/TextField/TextField';
import { Location } from '@portals/icons';
import { getFixedT } from 'i18next';
import React, { useCallback } from 'react';

import { usePreventSafariAutozoom } from '../../../../../hooks';

const t = getFixedT(null, 'core-immobilien');

export type EstateSearchAutocompleteInputProps = {
  params: AutocompleteRenderInputParams;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onBlur: () => void;
  textFieldProps?: TextFieldProps;
};

const styles: SxProps = {
  '& .MuiInputBase-root': {
    borderTopRightRadius: { sm: '0' },
    borderBottomRightRadius: { sm: '0' },
  },
};

export const EstateSearchAutocompleteInput: React.FC<EstateSearchAutocompleteInputProps> = ({
  params,
  value,
  setValue,
  onBlur,
  ...textFieldProps
}) => {
  const preventSafariAutozoom = usePreventSafariAutozoom();

  const onChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  return (
    <TextField
      {...params}
      slotProps={{
        input: {
          ...params.InputProps,
          startAdornment: <Location />,
          endAdornment: null,
        },
      }}
      onChange={onChange}
      onBlur={onBlur}
      label={value && t('where')}
      placeholder={t('where')}
      {...textFieldProps}
      {...preventSafariAutozoom}
      sx={styles}
      className="zip-city-input"
    />
  );
};

EstateSearchAutocompleteInput.displayName = 'EstateSearchAutocompleteInput';

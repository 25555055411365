import { SxProps } from '@mui/material';
import { toggleButtonClasses } from '@mui/material/ToggleButton';

import { theme } from '../../../themes/main';

export const styles: Record<string, SxProps> = {
  buttonGroupWrapper: {
    p: { xs: 5, lg: 6 },
  },
  toggleButtonGroup: {
    gap: 2,
  },
  toggleButton: {
    '&.MuiToggleButtonGroup-grouped': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: theme.borderRadius.xs,
      borderColor: theme.palette.grey['400'],
    },
    [`&.${toggleButtonClasses.selected}`]: {
      borderColor: theme.palette.green.main,
      backgroundColor: theme.palette.green.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.green.main,
      },
    },
  },
  selectFieldItem: {
    p: { lg: 6 },
  },
};

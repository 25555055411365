import { EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { get, trim } from 'lodash-es';

import { SortBy } from '../config';
import { type FilterValuesProps, QueryParams } from '../types';
import { castToStringArray } from '../utils';

export const getFiltersFromParams = (searchParams: EstateSearchProps): FilterValuesProps => {
  const estateTypeId = get(searchParams, 'estateTypeId', null);
  return {
    zipCityEstateId: get(searchParams, 'zipCityEstateId', null),
    perimeter: get(searchParams, 'perimeter', null),
    sortBy: get(searchParams, 'sortBy', SortBy.DISTANCE_SMALLEST),
    marketingType: get(searchParams, 'marketingType', null),
    objectType: get(searchParams, 'objectType', null),
    estateTypeId: estateTypeId ? +estateTypeId : null,
    usageType: get(searchParams, 'usageType', null),
    minPrice: get(searchParams, 'minPrice', null),
    maxPrice: get(searchParams, 'maxPrice', null),
    minRent: get(searchParams, 'minRent', null),
    maxRent: get(searchParams, 'maxRent', null),
    minMarketValue: get(searchParams, 'minMarketValue', null),
    maxMarketValue: get(searchParams, 'maxMarketValue', null),
    minLease: get(searchParams, 'minLease', null),
    maxLease: get(searchParams, 'maxLease', null),
    minLivingSpace: get(searchParams, 'minLivingSpace', null),
    maxLivingSpace: get(searchParams, 'maxLivingSpace', null),
    minPropertySize: get(searchParams, 'minPropertySize', null),
    maxPropertySize: get(searchParams, 'maxPropertySize', null),
    minTotalSpace: get(searchParams, 'minTotalSpace', null),
    maxTotalSpace: get(searchParams, 'maxTotalSpace', null),
    minNumberRooms: get(searchParams, 'minNumberRooms', null),
    maxNumberRooms: get(searchParams, 'maxNumberRooms', null),
    flatTypes: castToStringArray(get(searchParams, 'flatTypes[]', [])),
    houseTypes: castToStringArray(get(searchParams, 'houseTypes[]', [])),
    propertyTypes: castToStringArray(get(searchParams, 'propertyTypes[]', [])),
    interestTypes: castToStringArray(get(searchParams, 'interestTypes[]', [])),
    equipment: castToStringArray(get(searchParams, 'equipment[]', [])),
    conditionDevelopment: get(searchParams, 'conditionDevelopment', null),
    isNewBuilding: get(searchParams, 'isNewBuilding', null),
  };
};

export const isValidSearch = (queryParams: QueryParams): boolean => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';

  if (trim(queryParams.zipCityEstateId) === '') {
    return false;
  }

  if (!useImmobilienApi && trim(queryParams.objectType) === '') {
    return false;
  }

  if (useImmobilienApi && trim(queryParams.estateTypeId) === '') {
    return false;
  }

  if (trim(queryParams.usageType) === '') {
    return false;
  }

  return trim(queryParams.marketingType) !== '';
};

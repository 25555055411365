import type { ThemeOptions } from '@mui/material';
import { createTheme as createBaseTheme } from '@portals/core/src/themes/sde/main';
import type { Theme } from '@portals/core/src/themes/themes';

export interface ImmobilienMainTheme extends Theme {
  borderRadius?: {
    xs?: number | CSSStyleDeclaration['borderRadius'];
    sm?: number | CSSStyleDeclaration['borderRadius'];
    md?: number | CSSStyleDeclaration['borderRadius'];
    lg?: number | CSSStyleDeclaration['borderRadius'];
  };
}

const options = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 720,
      md: 720,
      lg: 1440,
      xl: 1600,
      text: 980,
    },
  },
  palette: {
    secondary: {
      dark: '#292929',
      darker: '#000000',
      main: '#565656',
      contrastText: '#FFFFFF',
    },
  },
  borderRadius: {
    // actual basevalue: 6px
    xs: '5px',
    sm: 1,
    md: 2,
    lg: 3,
  },
};

export function createTheme(...overrideTheme: ThemeOptions[]): ImmobilienMainTheme {
  return createBaseTheme(options, ...overrideTheme);
}

export const theme: ImmobilienMainTheme = createTheme();

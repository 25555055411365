import { SxProps } from '@mui/material';

import { theme } from '../../../themes/main';

export const styles: Record<string, SxProps> = {
  textField: {
    '& .MuiInputBase-input': {
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  textFieldIconButton: {
    position: 'absolute',
    right: 0,
    px: theme.spacing(5),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

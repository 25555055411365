import { Box } from '@mui/material';
import { Euro, SquareMeters } from '@portals/icons';
import { MarketingType } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useFormikContext } from 'formik';
import { getFixedT } from 'i18next';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import { EstateFilterTypes, FilterConfigFormat } from '../../../../config';
import { CatalogsContext } from '../../../../context';
import { Room } from '../../../../icons';
import { FilterConfigEstateTypeFilter, getFilterConfig } from '../../../../utils/estateTypeIdUtils';
import { EstateSearchFilterSelectInput } from './EstateSearchFilterInputs/EstateSearchFilterSelectInput';
import { EstateSearchFilterTextInput } from './EstateSearchFilterInputs/EstateSearchFilterTextInput';

interface Props {
  marketingType: MarketingType;
  estateTypeId: number;
  setValidationSchema: (validationSchema: unknown) => void;
}

const t = getFixedT(null, 'core-immobilien');

export const EstateSearchFilterView = ({
  marketingType,
  estateTypeId,
  setValidationSchema,
}: Props): React.ReactElement => {
  const { loading: catalogsLoading, roomsFrom: catalogsRoomsFrom } = useContext(CatalogsContext);

  const roomsItems = useMemo(() => {
    if (catalogsLoading || !catalogsRoomsFrom || catalogsRoomsFrom.length === 0) {
      return [];
    }
    return [
      {
        value: Number.MAX_VALUE,
        label: t('estateSearch.any'),
      },
      ...catalogsRoomsFrom
        .filter((item) => item.id > -1)
        .map((item) => ({
          value: item.id,
          label: item.code,
        })),
    ];
  }, [catalogsLoading, catalogsRoomsFrom]);

  const filterConfig: FilterConfigEstateTypeFilter = getFilterConfig(
    FilterConfigFormat.EstateFilterType
  ) as FilterConfigEstateTypeFilter;

  const singleFilterConfig = useMemo(
    () => filterConfig?.find((item) => item.marketingType === marketingType && item.estateTypeId === estateTypeId),
    [filterConfig, marketingType, estateTypeId]
  );

  useEffect(() => {
    setValidationSchema(singleFilterConfig?.validationSchema);
  }, [setValidationSchema, singleFilterConfig]);

  const { values, errors, setFieldTouched, setFieldValue, handleBlur } = useFormikContext();

  const checkDefaultItemLabel = useCallback(
    (value) => {
      return !value || value === '' || value === Number.MAX_VALUE ? t('estateSearch.any') : value;
    },
    [roomsItems, t]
  );

  const checkDefaultItemValue = useCallback(
    (value) => {
      return value === Number.MAX_VALUE ? null : value;
    },
    [roomsItems]
  );

  const setFormikValue = useCallback(
    (type, value) => {
      setFieldTouched(type, true);
      setFieldValue(type, checkDefaultItemValue(value), true);
    },
    [setFieldTouched, setFieldValue, checkDefaultItemValue]
  );

  const handleFieldChange = useCallback(
    (fieldType: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormikValue(fieldType, event?.target?.value);
    },
    [setFormikValue]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {singleFilterConfig?.fields?.map((field) => {
        const initialValue = values[field.type];
        const error = Boolean(errors[field.type]);
        const errorMessage = error ? String(errors[field.type]) : '';

        switch (field.type) {
          case EstateFilterTypes.MAX_PRICE:
          case EstateFilterTypes.MAX_RENT:
          case EstateFilterTypes.MAX_MARKET_VALUE:
          case EstateFilterTypes.MAX_LEASE:
            return (
              <EstateSearchFilterTextInput
                key={field.type}
                name="priceSearch"
                label={`${t('priceSearchPlaceholder')} (€)`}
                value={initialValue}
                onChange={handleFieldChange(field.type)}
                onBlur={handleBlur}
                error={error}
                errorMessage={errorMessage}
                slotProps={{ input: { startAdornment: <Euro /> } }}
              />
            );
          case EstateFilterTypes.MIN_LIVING_SPACE:
            return (
              <EstateSearchFilterTextInput
                key={field.type}
                name="livingSpaceSearch"
                label={`${t('livingSpaceSearchPlaceholder')} (m²)`}
                value={initialValue}
                onChange={handleFieldChange(field.type)}
                onBlur={handleBlur}
                error={error}
                errorMessage={errorMessage}
                slotProps={{ input: { startAdornment: <SquareMeters /> } }}
              />
            );
          case EstateFilterTypes.MIN_NUMBER_ROOMS:
            return (
              <Box sx={{ minWidth: { md: '150px', lg: '200px' } }}>
                <EstateSearchFilterSelectInput
                  key={field.type}
                  name="roomSearch"
                  label={t('roomSearchPlaceholder')}
                  value={initialValue}
                  renderValue={checkDefaultItemLabel}
                  items={roomsItems}
                  onChange={handleFieldChange(field.type)}
                  onBlur={handleBlur}
                  error={error}
                  errorMessage={errorMessage}
                  startAdornment={<Room />}
                />
              </Box>
            );
          case EstateFilterTypes.MIN_PROPERTY_SIZE:
            return (
              <EstateSearchFilterTextInput
                key={field.type}
                name="propertySizeSearch"
                label={`${t('propertySizeSearchPlaceholder')} (m²)`}
                value={initialValue}
                onChange={handleFieldChange(field.type)}
                onBlur={handleBlur}
                error={error}
                errorMessage={errorMessage}
                slotProps={{ input: { startAdornment: <SquareMeters /> } }}
              />
            );
          case EstateFilterTypes.MIN_TOTAL_SPACE:
            return (
              <EstateSearchFilterTextInput
                key={field.type}
                name="totalSpaceSearch"
                label={`${t('totalSpaceSearchPlaceholder')} (m²)`}
                value={initialValue}
                onChange={handleFieldChange(field.type)}
                onBlur={handleBlur}
                error={error}
                errorMessage={errorMessage}
                slotProps={{ input: { startAdornment: <SquareMeters /> } }}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};

EstateSearchFilterView.displayName = 'EstateSearchFilterView';

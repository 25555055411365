import { Box } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInViewRef } from 'rooks';

import { EstateListView, MapView, NoResult } from '../../components';
import { ResultListTypes } from '../../config';
import { useLocationHash, useSearchIsSaved } from '../../hooks';
import type { FilterValuesProps } from '../../types';
import { getFiltersFromParams } from '../../utils';
import type { EstateResultProps } from './EstateResult.types';
import { FilterAndResultControlView } from './FiltersView/FilterAndResultControlView/FilterAndResultControlView';
import { FiltersView } from './FiltersView/FiltersView';
import { useMapEstates } from './useFetchMapItems/useFetchMapItems';
import { useFilterDrawer } from './useFilterDrawer/useFilterDrawer';

export const EstateResult: React.FC<EstateResultProps> = ({
  totalItems,
  firstPageEstates,
  searchParams,
  page = 1,
  renderBookmarkButton,
  renderEstateLink,
  handlePageRoutingByFilerValues,
}: EstateResultProps) => {
  const currentPage = useRef(page);
  const [estates, setEstates] = useState(firstPageEstates || []);
  const [mapEstates, setMapEstates] = useState<typeof estates>([]);
  const [showLoader, setShowLoader] = useState(false);
  const { isActive: showMap, setHash } = useLocationHash('map');
  const resultListType = showMap ? ResultListTypes.MAP : ResultListTypes.LIST;
  const [estateResultFilterViewRef, estateResultFilterViewInView] = useInViewRef();
  const [filterValues, setFilterValues] = useState<FilterValuesProps>(getFiltersFromParams(searchParams));
  const { resultFilterDrawerOptions, setResultFilterDrawerOptions, showResultFilterDrawer, hideResultFilterDrawer } =
    useFilterDrawer();

  useEffect(() => {
    if (firstPageEstates) {
      setEstates(firstPageEstates);
    }
  }, [firstPageEstates]);

  const { saveButtonLabelFormatter, toggleSearchIsSaved } = useSearchIsSaved();
  const saveSearchButtonLabel = useMemo(
    () => saveButtonLabelFormatter(searchParams),
    [searchParams, saveButtonLabelFormatter]
  );
  const onClickSaveSearch = useCallback(async () => {
    return toggleSearchIsSaved(searchParams);
  }, [searchParams, toggleSearchIsSaved]);

  handlePageRoutingByFilerValues(filterValues);
  useMapEstates(searchParams, resultListType, mapEstates, setMapEstates, setShowLoader);

  const handleResultListSortChange = useCallback((value) => {
    setFilterValues((prevState) => ({
      ...prevState,
      sortBy: value,
    }));
  }, []);

  const handleResultListToggleChange = useCallback(
    (event, value) => {
      if (value) {
        setHash(value === ResultListTypes.MAP);
      }
    },
    [setHash]
  );

  const handleResetFilters = useCallback(() => {
    setFilterValues(getFiltersFromParams(searchParams));
  }, [searchParams]);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: resultListType === ResultListTypes.MAP ? '100vh' : 'auto',
        }}
      >
        <FilterAndResultControlView
          searchParams={searchParams}
          totalItems={totalItems}
          estates={estates}
          onClickSaveSearch={onClickSaveSearch}
          saveSearchButtonLabel={saveSearchButtonLabel}
          filterValues={filterValues}
          showResultFilterDrawer={showResultFilterDrawer}
          estateResultFilterViewInView={estateResultFilterViewInView}
          resultListType={resultListType}
          onResultListToggleChange={handleResultListToggleChange}
          onResultListSortChange={handleResultListSortChange}
          estateResultFilterViewRef={estateResultFilterViewRef}
          isLoading={showLoader}
        />
        {estates.length === 0 && (
          <NoResult
            onClickSaveSearch={onClickSaveSearch}
            saveSearchButtonLabel={saveSearchButtonLabel}
            isSaveSearchButtonDisabled={false}
          />
        )}
        {estates.length > 0 && resultListType === ResultListTypes.LIST && (
          <EstateListView
            searchParams={searchParams}
            totalItems={totalItems}
            estates={estates}
            setEstates={setEstates}
            showLoader={showLoader}
            onClickSaveSearch={onClickSaveSearch}
            saveSearchButtonLabel={saveSearchButtonLabel}
            isSaveSearchButtonDisabled={false}
            showSaveSearchButton={!estateResultFilterViewInView}
            currentPage={currentPage}
            setShowLoader={setShowLoader}
            renderEstateLink={renderEstateLink}
            renderBookmarkButton={renderBookmarkButton}
          />
        )}
        {resultListType === ResultListTypes.MAP && <MapView estates={mapEstates} />}
      </Box>
      <FiltersView
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        resultFilterDrawerOptions={resultFilterDrawerOptions}
        setResultFilterDrawerOptions={setResultFilterDrawerOptions}
        currentPage={currentPage}
        close={hideResultFilterDrawer}
        resetFilters={handleResetFilters}
      />
    </>
  );
};

EstateResult.displayName = 'EstateResult';

import { Box, Grid2 as Grid, Stack, Typography } from '@mui/material';
import { Home } from '@portals/icons';
import { Form, Formik } from 'formik';
import { getFixedT } from 'i18next';
import { assign, get, isEqual, keys, pick } from 'lodash-es';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { object } from 'yup';

import { ButtonGroupSelectTextField, HitButton } from '../../../components';
import { getAllMarketingTypes, getPerimeterOptions } from '../../../config';
import { CatalogsContext } from '../../../context';
import { useEstateSearchLocalStorage } from '../../../hooks';
import {
  fieldHasValue,
  getEstateTypeIdOptions,
  getMarketingTypeEstateTypeIdLabel,
  prefillDefaultSearchValues,
  removeEmptyParams,
  removeNotWhitelistedParamsByEstateTypeId,
} from '../../../utils';
import type { EstateSearchMainFormProps, EstateSearchProps } from '../EstateSearch.types';
import { getBasicValidationSchema, getDefaultValues } from '../EstateSearch.utils';
import { styles } from './EstateSearch.styles';
import { EstateSearchAutocompleteWrapper } from './EstateSearchAutocomplete/EstateSearchAutocompleteWrapper';
import { EstateSearchFilterView } from './EstateSearchFilterView/EstateSearchFilterView';
import { EstateSearchSelect } from './EstateSearchSelect/EstateSearchSelect';
import { EstateSearchSkeleton } from './EstateSearchSkeleton/EstateSearchSkeleton';

const t = getFixedT(null, 'core-immobilien');

export const EstateSearch = ({
  isSkeletonEnabled = false,
  perimeter,
  updateEstateSearchHandler,
}: EstateSearchProps): React.ReactElement => {
  const { lastSearchQuery, updateLastSearchQuery } = useEstateSearchLocalStorage();
  const defaultValues = getDefaultValues(t, perimeter);
  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState<EstateSearchMainFormProps>(defaultValues);
  const [isReadyToRender, setIsReadyToRender] = useState(false);
  const {
    loading: catalogsLoading,
    radii: catalogsRadii,
    estateSubTypes: catalogsEstateSubTypes,
  } = useContext(CatalogsContext);

  const setInititalValuesfromLocalStorage = () => {
    const selectedCategory = {
      lastClickedMarketingTypeValue: get(lastSearchQuery, 'marketingType', defaultValues.marketingType),
      marketingTypeObjectTypeTextfieldValue: getMarketingTypeEstateTypeIdLabel({
        estateSubTypes: catalogsEstateSubTypes,
        marketingType: get(lastSearchQuery, 'marketingType', defaultValues.marketingType),
        estateTypeId: get(lastSearchQuery, 'estateTypeId', defaultValues.estateTypeId),
      }),
    };

    const allowedSearchFormProps = pick(lastSearchQuery, keys(defaultValues));
    const initialSearchFormProps = { ...allowedSearchFormProps, ...selectedCategory } as EstateSearchMainFormProps;
    setInitialValues(initialSearchFormProps);
  };

  useEffect(() => {
    if (catalogsEstateSubTypes?.length > 0) {
      if (lastSearchQuery) {
        setInititalValuesfromLocalStorage();
      }
      setIsReadyToRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogsEstateSubTypes]);

  const normalizePerimeterValue = (value) => {
    return value === Number.MAX_VALUE ? null : value;
  };

  const FormikOnChange = ({ values, callback }) => {
    useEffect(() => {
      callback(values);
    }, [values]);

    return null;
  };

  const persistQuery = useCallback(
    (values: EstateSearchMainFormProps) => {
      const defaultSearchValues = prefillDefaultSearchValues(values);
      const whitelistedApiParams = removeNotWhitelistedParamsByEstateTypeId(defaultSearchValues);
      const queryParamsWithoutEmpty = removeEmptyParams(whitelistedApiParams);

      if (!isEqual(queryParamsWithoutEmpty, lastSearchQuery)) {
        updateLastSearchQuery(queryParamsWithoutEmpty);
      }
    },
    [lastSearchQuery, updateLastSearchQuery]
  );

  const handleFormSubmit = useCallback(
    async (values) => {
      const defaultSearchValues = prefillDefaultSearchValues(values);
      const whitelistedApiParams = removeNotWhitelistedParamsByEstateTypeId(defaultSearchValues);
      const queryParamsWithoutEmpty = removeEmptyParams(whitelistedApiParams);

      updateEstateSearchHandler(queryParamsWithoutEmpty);
    },
    [updateEstateSearchHandler]
  );

  return (
    <Box data-id="estate-search" className="search-form" translate="no">
      {(!isReadyToRender || catalogsLoading) && isSkeletonEnabled && <EstateSearchSkeleton />}
      {isReadyToRender && !catalogsLoading && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={object().shape(validationSchema)}
          onSubmit={handleFormSubmit}
        >
          {({ values, setFieldTouched, setFieldValue, isValid }) => {
            const handlePerimeterChange = (value) => {
              setFieldTouched('perimeter', true);
              setFieldValue('perimeter', normalizePerimeterValue(value), true);
            };

            const handleButtonGroupClose = () => {
              if (values.marketingType !== values.lastClickedMarketingTypeValue) {
                setFieldTouched('marketingType', true);
                setFieldValue('marketingType', values.lastClickedMarketingTypeValue, true);
              }
            };

            const handleButtonGroupButtonClick = (value) => {
              setFieldTouched('marketingType', true);
              setFieldValue('marketingType', value, true);
              setFieldValue(
                'marketingTypeObjectTypeTextfieldValue',
                getMarketingTypeEstateTypeIdLabel({
                  estateSubTypes: catalogsEstateSubTypes,
                  marketingType: value,
                  estateTypeId: values.estateTypeId,
                }),
                false
              );
              return false;
            };

            const handleButtonGroupSelectClick = (selectedMenuItem) => {
              setFieldTouched('lastClickedMarketingTypeValue', true);
              setFieldValue('lastClickedMarketingTypeValue', values.marketingType, true);
              setFieldTouched('estateTypeId', true);
              setFieldValue('estateTypeId', selectedMenuItem, true);
              setFieldValue(
                'marketingTypeObjectTypeTextfieldValue',
                getMarketingTypeEstateTypeIdLabel({
                  estateSubTypes: catalogsEstateSubTypes,
                  marketingType: values.marketingType,
                  estateTypeId: selectedMenuItem,
                }),
                false
              );
              return true;
            };

            const updateValidationSchema = (subfieldValidationSchema) => {
              const currentValidationSchema = assign({}, getBasicValidationSchema(t), subfieldValidationSchema);
              if (!isEqual(Object.keys(currentValidationSchema), Object.keys(validationSchema))) {
                setValidationSchema(currentValidationSchema);
              }
            };

            return (
              <Form>
                <FormikOnChange values={values} callback={persistQuery} />

                <Typography variant="h3" component="h2" sx={styles.title}>
                  {t('estateSearch.title')}
                </Typography>

                <Grid
                  container
                  columns={15}
                  rowSpacing={{ xs: 5, md: 5, lg: 7 }}
                  columnSpacing={{ xs: 5, md: 3, lg: 5 }}
                >
                  <Grid size={{ xs: 15, md: 11 }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 5, sm: 0 }} alignItems="stretch">
                      <Box sx={{ flexGrow: 1 }}>
                        <EstateSearchAutocompleteWrapper fieldName="zipCityEstateId" />
                      </Box>
                      <Box sx={styles.estateSearchSelectBox}>
                        <EstateSearchSelect
                          testId="perimeter"
                          label={t('perimeter')}
                          initialValue={values.perimeter}
                          items={getPerimeterOptions(t, catalogsRadii)}
                          onChange={handlePerimeterChange}
                          formatValue={(value) => t('estateSearch.perimeter', { value: value })}
                          variant="outlined"
                          className="perimeter-select"
                        />
                      </Box>
                    </Stack>
                  </Grid>

                  <Grid size={{ xs: 15, md: 4 }}>
                    <ButtonGroupSelectTextField
                      InputAdornmentIcon={Home}
                      label={t('what')}
                      inputValue={values.marketingTypeObjectTypeTextfieldValue}
                      buttonOptions={getAllMarketingTypes(t)}
                      buttonValue={values.marketingType}
                      selectOptions={getEstateTypeIdOptions(catalogsEstateSubTypes, values.marketingType)}
                      selectValue={values.estateTypeId}
                      highlightSelectedOption={values.marketingType === values.lastClickedMarketingTypeValue}
                      onCloseHandler={handleButtonGroupClose}
                      onButtonClick={handleButtonGroupButtonClick}
                      onSelectClick={handleButtonGroupSelectClick}
                      menuMaxHeight={310}
                    />
                  </Grid>

                  <Grid size={{ xs: 15, md: 11 }}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 5, md: 3, lg: 5 }}>
                      <EstateSearchFilterView
                        setValidationSchema={updateValidationSchema}
                        marketingType={values.lastClickedMarketingTypeValue}
                        estateTypeId={values.estateTypeId}
                      />
                    </Stack>
                  </Grid>

                  <Grid size={{ xs: 15, md: 4 }} sx={styles.hitButton}>
                    <HitButton disabled={!fieldHasValue(values.zipCityEstateId) || !isValid} />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

EstateSearch.displayName = 'EstateSearch';

import { frontendConfig, FrontendConfigKey } from '../frontend/FrontendConfig';
import { if6Config, IF6ConfigKey } from '../if6/IF6Config';

export interface sharedConfig {
  NEXT_PUBLIC_COOKIE_DOMAIN?: string;
  NEXT_PUBLIC_HOST?: string;
  NEXT_PUBLIC_GTM_ID?: string;
  NEXT_PUBLIC_GTM_AUTH?: string;
  NEXT_PUBLIC_GTM_ENV?: string;
  YELLOWMAPS_SYSTEM_PARTNER?: string;
  YELLOWMAP_TILE_URL?: string;
  YELLOWMAP_AUTOCOMPLETE_URL?: string;
  YELLOWMAPS_SECURITY_ID?: string;
  YELLOWMAP_API_URL?: string;
  LOGGING_ENDPOINT?: string;
  LOCALSTORAGE_BOOKMARKS?: string;
  FINANCE_CERTIFICATE_URL?: string;
  APP_CONTEXT?: string;
  SIP_API_PROXY?: string;
  BANK_LOGO_PATH?: string;
  USE_IMMOBILIEN_API?: string;
}

export abstract class Config {
  protected config: frontendConfig | if6Config;
  public abstract set(key: FrontendConfigKey | IF6ConfigKey, value: string): void;
  public abstract get(key: FrontendConfigKey | IF6ConfigKey): string;
}

export class ConfigProvider {
  public static setConfig(config: Config): void {
    globalThis.appConfig = config;
  }

  public static getConfig(): Config {
    return globalThis.appConfig;
  }

  public static getHost(): string {
    return globalThis.appConfig.get('NEXT_PUBLIC_HOST');
  }
}

import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { EstateItem, EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { ConfigProvider } from '@portals/sip-client-data/src/general/Config';
import { isEqual, merge } from 'lodash-es';
import { useEffect, useRef } from 'react';

import { ResultListTypes } from '../../../config';
import { Logger, removeBrackets, removeEmptyParams } from '../../../utils';

export type UseFetchMapItemsProps = (
  searchParams: EstateSearchProps,
  resultListType: ResultListTypes,
  mapEstates: EstateItem[],
  setMapEstates: React.Dispatch<React.SetStateAction<EstateItem[]>>,
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>
) => void;

export const useMapEstates: UseFetchMapItemsProps = (
  searchParams,
  resultListType,
  mapEstates,
  setMapEstates,
  setShowLoader
) => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch
  const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';

  const cachedSearchParams = useRef(searchParams);

  useEffect(() => {
    if (
      resultListType === ResultListTypes.MAP &&
      (mapEstates.length === 0 || !isEqual(searchParams, cachedSearchParams.current))
    ) {
      cachedSearchParams.current = searchParams;

      let didCancel = false;

      const fetchMapEstates = async (page = 1, prevEstates?: EstateItem[]) => {
        // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: set endless limit to 200
        const endlessLimit = useImmobilienApi ? 200 : 20;
        searchParams['page'] = page;
        const query = removeEmptyParams(
          merge({}, removeBrackets(searchParams), {
            limit: endlessLimit,
            page: page,
          })
        );
        setShowLoader(true);

        try {
          setShowLoader(true);
          const estatesResponse = await ApiClientProvider.getApiClient().getEstates(query);

          if (!didCancel) {
            const estates = prevEstates
              ? [...prevEstates, ...estatesResponse.estates]
              : ([...estatesResponse.estates] as EstateItem[]);
            await setMapEstates(estates);
            if (page < estatesResponse.pageCount) {
              fetchMapEstates(page + 1, estates);
            } else {
              setShowLoader(false);
            }
          }
        } catch (e) {
          Logger.error(e);
          setShowLoader(false);
        }
      };

      fetchMapEstates(1);

      return () => {
        didCancel = true;
      };
    }
  }, [searchParams, resultListType]);
};

import {
  MarketingType,
  RawFilterConfigCriteria,
  RawFilterConfigItem,
} from '@portals/sip-client-data/src/general/ApiClientTypes';
import { filterConfig } from '@portals/sip-client-data/src/general/utils/estateTypeUtils';
import { number, NumberSchema } from 'yup';

import { Criteria, EstateFilterTypes, FilterConfigFormat } from '../config';
import {
  commonApiParamsImmobilienApi,
  leaseApiParams,
  livingSpaceApiParams,
  numberRoomsApiParams,
  priceApiParams,
  propertySizeApiParams,
  rentApiParams,
  totalSpaceApiParams,
} from './searchParamUtils';

type ValidationSchema = Record<string, NumberSchema>;

export type FilterConfigEstateTypeFilter = Array<{
  marketingType: MarketingType;
  estateTypeId: number;
  fields: Array<{ type: EstateFilterTypes }>;
  validationSchema?: ValidationSchema;
}>;

export type FilterConfigCriteria = Array<{
  marketingType: MarketingType;
  estateTypeId: number;
  fields: Array<Criteria>;
}>;

type FilterConfigItem = {
  marketingType: MarketingType;
  estateTypeId: number;
  fields: Array<{ type: EstateFilterTypes } | Criteria>;
  validationSchema?: ValidationSchema;
};

export type FilterConfig = Array<FilterConfigItem>;

const getFilterFieldMaxPrice = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MAX_PRICE } : Criteria.PRICE;

const getFilterFieldMaxRent = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MAX_RENT } : Criteria.RENT;

const getFilterFieldMaxLease = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MAX_LEASE } : Criteria.LEASE;

const getFilterFieldMinLivingSpace = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MIN_LIVING_SPACE } : Criteria.LIVING_SPACE;

const getFilterFieldMinNumberRooms = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MIN_NUMBER_ROOMS } : Criteria.ROOMS;

const getFilterFieldMinPropertySize = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType
    ? { type: EstateFilterTypes.MIN_PROPERTY_SIZE }
    : Criteria.PROPERTY_SIZE;

const getFilterFieldMinTotalSpace = (format: FilterConfigFormat): { type: EstateFilterTypes } | Criteria =>
  format === FilterConfigFormat.EstateFilterType ? { type: EstateFilterTypes.MIN_TOTAL_SPACE } : Criteria.TOTAL_SPACE;

const getFormattedFields = (
  rawConfig: RawFilterConfigItem,
  format: FilterConfigFormat
): Array<{ type: EstateFilterTypes } | Criteria> => {
  const formattedFields: Array<{ type: EstateFilterTypes } | Criteria> = [];
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.PRICE)) {
    formattedFields.push(getFilterFieldMaxPrice(format));
  }
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.RENT)) {
    formattedFields.push(getFilterFieldMaxRent(format));
  }
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.LEASE)) {
    formattedFields.push(getFilterFieldMaxLease(format));
  }
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.LIVING_SPACE)) {
    formattedFields.push(getFilterFieldMinLivingSpace(format));
  }
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.PROPERTY_SIZE)) {
    formattedFields.push(getFilterFieldMinPropertySize(format));
  }
  if (rawConfig.fields?.includes(RawFilterConfigCriteria.TOTAL_SPACE)) {
    formattedFields.push(getFilterFieldMinTotalSpace(format));
  }
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.ROOMS)) {
    formattedFields.push(getFilterFieldMinNumberRooms(format));
  }
  return formattedFields;
};

const priceValidationSchema = () => number().integer().min(0).max(99999999).nullable();
const livingSpaceValidationSchema = () => number().integer().min(0).max(10000).nullable();
const numberRoomsValidationSchema = () => number().min(0).max(10000).nullable();
const propertySizeValidationSchema = () => number().integer().min(0).max(10000).nullable();
const totalSpaceValidationSchema = () => number().integer().min(0).max(10000).nullable();

const addValidationSchema = (rawConfig: RawFilterConfigItem): ValidationSchema => {
  const validationSchema: ValidationSchema = {};
  if (
    rawConfig?.fields?.includes(RawFilterConfigCriteria.PRICE) ||
    rawConfig?.fields?.includes(RawFilterConfigCriteria.RENT) ||
    rawConfig?.fields?.includes(RawFilterConfigCriteria.LEASE)
  ) {
    validationSchema.maxPrice = priceValidationSchema();
  }
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.LIVING_SPACE)) {
    validationSchema.minLivingSpace = livingSpaceValidationSchema();
  }
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.PROPERTY_SIZE)) {
    validationSchema.minPropertySize = propertySizeValidationSchema();
  }
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.TOTAL_SPACE)) {
    validationSchema.minTotalSpace = totalSpaceValidationSchema();
  }
  if (rawConfig?.fields?.includes(RawFilterConfigCriteria.ROOMS)) {
    validationSchema.minNumberRooms = numberRoomsValidationSchema();
  }
  return validationSchema;
};

export const getFilterConfig = (format: FilterConfigFormat): FilterConfig => {
  const formattedFilterConfig: FilterConfig = [];
  filterConfig.forEach((rawConfig: RawFilterConfigItem) => {
    formattedFilterConfig.push({
      marketingType: rawConfig.marketingType,
      estateTypeId: rawConfig.estateTypeId,
      fields: getFormattedFields(rawConfig, format),
      validationSchema: format === FilterConfigFormat.EstateFilterType ? addValidationSchema(rawConfig) : undefined,
    });
  });
  return formattedFilterConfig;
};

export const getApiParams = (marketingType: MarketingType, estateTypeId: number): Array<string> => {
  const filterConfig: FilterConfigCriteria = getFilterConfig(FilterConfigFormat.Criteria) as FilterConfigCriteria;
  const singleFilterConfig = filterConfig?.find(
    (item) => `${item.marketingType}` === `${marketingType}` && `${item.estateTypeId}` === `${estateTypeId}`
  );
  let apiParams = [...commonApiParamsImmobilienApi];
  if (singleFilterConfig?.fields?.includes(Criteria.PRICE)) {
    apiParams = [...apiParams, ...priceApiParams];
  }
  if (singleFilterConfig?.fields?.includes(Criteria.RENT)) {
    apiParams = [...apiParams, ...rentApiParams];
  }
  if (singleFilterConfig?.fields?.includes(Criteria.LEASE)) {
    apiParams = [...apiParams, ...leaseApiParams];
  }
  if (singleFilterConfig?.fields?.includes(Criteria.LIVING_SPACE)) {
    apiParams = [...apiParams, ...livingSpaceApiParams];
  }
  if (singleFilterConfig?.fields?.includes(Criteria.PROPERTY_SIZE)) {
    apiParams = [...apiParams, ...propertySizeApiParams];
  }
  if (singleFilterConfig?.fields?.includes(Criteria.TOTAL_SPACE)) {
    apiParams = [...apiParams, ...totalSpaceApiParams];
  }
  if (singleFilterConfig?.fields?.includes(Criteria.ROOMS)) {
    apiParams = [...apiParams, ...numberRoomsApiParams];
  }
  return apiParams;
};

import axios, { AxiosResponse } from 'axios';
import { stringifyUrl } from 'query-string';

import { ApiClient } from '../general/ApiClient';
import {
  mapAutocompleteData,
  mapEstateContactRequestProps,
  mapFrontendSearchParamsToApiParams,
} from '../general/ApiClientMapping/ApiClientMapping';
import {
  AutoCompleteOption,
  CatalogsResponse,
  EstateContactMessageProps,
  EstateCountResponse,
  EstateDetail,
  EstateListResponse,
  EstateSearchProps,
  FinanceData,
  MapQueryParams,
  TravelTimeQueryParams,
  UserEventData,
} from '../general/ApiClientTypes';
import { ConfigProvider } from '../general/Config';

export class FrontendApiClient implements ApiClient {
  private readonly frontendUrl: string;
  private readonly estateSearchEndpoint: string;
  private readonly estateUserEventEndpoint: string;
  private readonly estateContactFormSubmitEndpoint: string;

  constructor() {
    this.frontendUrl = ConfigProvider.getConfig().get('IMMOBILIEN_FRONTEND_URL') ?? '';
    this.estateSearchEndpoint = ConfigProvider.getConfig().get('ESTATE_SEARCH_ENDPOINT') ?? '/api/search';
    this.estateUserEventEndpoint = ConfigProvider.getConfig().get('ESTATE_USER_EVENT_ENDPOINT') ?? '/api/user-event';
    this.estateContactFormSubmitEndpoint =
      ConfigProvider.getConfig().get('ESTATE_CONTACT_FORM_SUBMIT_ENDPOINT') ?? '/api/contact-form-submit';
  }

  public async getEstateDetail(): Promise<EstateDetail> {
    throw new Error('Method not implemented.');
  }

  public async getEstates(searchParams: EstateSearchProps): Promise<EstateListResponse> {
    // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch
    const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
    const url = stringifyUrl(
      {
        url: `${this.frontendUrl}${useImmobilienApi ? '/api/immobilien-api/estates' : this.estateSearchEndpoint}`,
        query: mapFrontendSearchParamsToApiParams(searchParams, false),
      },
      { arrayFormat: 'bracket' }
    );
    const storage = sessionStorage;
    const key = `get_${url}`;
    const res = storage.getItem(key);
    if (res) {
      return JSON.parse(res) as EstateListResponse;
    }
    const estatesResponse = await axios.get(url);
    storage.setItem(key, JSON.stringify(estatesResponse.data));
    return estatesResponse.data as EstateListResponse;
  }

  public async getEstateCount(searchParams: EstateSearchProps): Promise<EstateCountResponse> {
    // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch
    const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
    const url = stringifyUrl(
      {
        url: `${this.frontendUrl}/api${useImmobilienApi ? '/immobilien-api/estates/count' : '/estate-total-items'}`,
        query: mapFrontendSearchParamsToApiParams(searchParams, true),
      },
      { arrayFormat: 'bracket' }
    );

    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  public async getCatalogs(): Promise<CatalogsResponse> {
    const response = await axios.get(`${this.frontendUrl}/api/catalogs`);
    return response?.data ?? null;
  }

  public async getAutoCompleteOptions(term: string): Promise<Array<AutoCompleteOption>> {
    // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch
    const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
    if (useImmobilienApi && term?.length < 3) {
      return [];
    }
    const response = await axios.get(
      `${this.frontendUrl}/api${useImmobilienApi ? '/immobilien-api' : ''}/autocomplete?term=${term}`
    );
    return mapAutocompleteData(response.data);
  }

  public async getLoans(query: FinanceData): Promise<AxiosResponse> {
    const uri = stringifyUrl({ url: `${this.frontendUrl}/api/loans`, query });
    const response = axios.get(uri);

    return response;
  }

  public async getPolygons(query: MapQueryParams): Promise<AxiosResponse> {
    const uri = stringifyUrl({ url: `${this.frontendUrl}/api/polygon`, query });
    const response = axios.get(uri);

    return response;
  }

  public async getPois(query: MapQueryParams): Promise<AxiosResponse> {
    const uri = stringifyUrl({ url: `${this.frontendUrl}/api/poi`, query });
    const response = axios.get(uri);

    return response;
  }

  public async getTravelTimes(query: TravelTimeQueryParams): Promise<AxiosResponse> {
    const uri = stringifyUrl({ url: `${this.frontendUrl}/api/distance`, query });
    const response = axios.get(uri);

    return response;
  }

  public async clientSendEstateContactMessage(contactMessage: EstateContactMessageProps): Promise<boolean> {
    // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch
    const useImmobilienApi = ConfigProvider.getConfig().get('USE_IMMOBILIEN_API') === 'true';
    const uri = stringifyUrl(
      {
        url: `${this.frontendUrl}${useImmobilienApi ? `/api/immobilien-api/${contactMessage.estateId}` : ''}${
          this.estateContactFormSubmitEndpoint
        }`,
      },
      { arrayFormat: 'bracket' }
    );

    try {
      const response = await axios.post(uri, mapEstateContactRequestProps(contactMessage), {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response?.data?.success || false;
    } catch (e) {
      return false;
    }
  }

  public async sendUserEvent(userEvent: UserEventData): Promise<void> {
    const url = stringifyUrl({ url: `${this.frontendUrl}${this.estateUserEventEndpoint}` }, { arrayFormat: 'bracket' });
    try {
      const response = await axios.post(url, userEvent, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (e) {
      return;
    }
  }
}

import { Popper as MuiPopper, PopperProps } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { trim } from 'lodash-es';
import React from 'react';

import { theme } from '../../../../../themes/main';

interface Props extends PopperProps {
  inputValue: string;
}

export const EstateSearchAutocompletePopper: React.FC<Props> = ({ inputValue, ...props }) => {
  if (trim(inputValue) === '') {
    return null;
  }

  return (
    <MuiPopper
      {...props}
      placement="bottom-start"
      modifiers={[{ name: 'flip', enabled: false }]}
      sx={{
        [`& .${autocompleteClasses.paper}`]: {
          mt: theme.spacing(4),
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: theme.borderRadius.md,
        },
        [`& .${autocompleteClasses.listbox} li:last-child hr`]: {
          display: 'none',
        },
      }}
    />
  );
};

EstateSearchAutocompletePopper.displayName = 'EstateSearchAutocompletePopper';

import {
  MarketingType,
  ObjectType,
  OfferType,
  RawFilterConfigCriteria,
  RawFilterConfigItem,
  SortBy,
  SortById,
} from '../../ApiClientTypes';
import { filterConfig, getEstateTypeIdFromObjectType } from '../../utils/estateTypeUtils';

export const mapSortByToId = (sortBy: SortBy): SortById => {
  switch (sortBy) {
    case SortBy.DISTANCE_SMALLEST:
      return SortById.DISTANCE_SMALLEST;
    case SortBy.PRICE_LOWEST:
      return SortById.PRICE_LOWEST;
    case SortBy.PRICE_HIGHEST:
      return SortById.PRICE_HIGHEST;
    case SortBy.PROPERTY_NEWEST:
      return SortById.PROPERTY_NEWEST;
    default:
      return SortById.DISTANCE_SMALLEST;
  }
};

export const mapSearchParamsToApiParams = (frontendParams: any, isCount?: boolean) => {
  let latitude = undefined;
  let longitude = undefined;
  let radius = frontendParams?.perimeter ?? undefined;
  let regionalSearch = undefined;
  const zipCityEstateId = frontendParams?.zipCityEstateId ?? frontendParams?.zip_city_estate_id ?? null;
  const coordsArray = zipCityEstateId ? zipCityEstateId.split('/') : null;
  if (coordsArray?.length >= 2) {
    latitude = parseFloat(coordsArray[0]) ?? undefined;
    longitude = parseFloat(coordsArray[1]) ?? undefined;
  }
  if ((!latitude || !longitude) && zipCityEstateId) {
    latitude = undefined;
    longitude = undefined;
    radius = undefined;
    regionalSearch = zipCityEstateId;
  }

  const marketingType = frontendParams?.marketingType ?? frontendParams?.marketing_type ?? undefined;
  let estateTypeIds = frontendParams?.estateTypeId ?? frontendParams?.estate_type_id ?? undefined;
  const objectTypes = frontendParams?.objectType ?? frontendParams?.object_type ?? undefined;

  if (!estateTypeIds && objectTypes) {
    const objectTypesArray: Array<ObjectType> = objectTypes.replace(/,/g, ';').split(';');
    estateTypeIds =
      objectTypesArray?.map((objectType: ObjectType) => getEstateTypeIdFromObjectType(objectType))?.join(',') ??
      undefined;
  }

  const singleFilterConfig: RawFilterConfigItem = filterConfig?.find(
    (item) => `${item.marketingType}` === `${marketingType}` && `${item.estateTypeId}` === `${estateTypeIds}`
  );

  let minPrice = undefined;
  let maxPrice = undefined;
  if (singleFilterConfig?.fields?.includes(RawFilterConfigCriteria.PRICE)) {
    minPrice = frontendParams?.minPrice ?? frontendParams?.min_price ?? undefined;
    maxPrice = frontendParams?.maxPrice ?? frontendParams?.max_price ?? undefined;
  }
  if (singleFilterConfig?.fields?.includes(RawFilterConfigCriteria.RENT)) {
    minPrice = frontendParams?.minRent ?? frontendParams?.min_rent ?? undefined;
    maxPrice = frontendParams?.maxRent ?? frontendParams?.max_rent ?? undefined;
  }
  if (singleFilterConfig?.fields?.includes(RawFilterConfigCriteria.LEASE)) {
    minPrice = frontendParams?.minLease ?? frontendParams?.min_lease ?? undefined;
    maxPrice = frontendParams?.maxLease ?? frontendParams?.max_lease ?? undefined;
  }

  let minSpace = undefined;
  let maxSpace = undefined;
  if (singleFilterConfig?.fields?.includes(RawFilterConfigCriteria.LIVING_SPACE)) {
    minSpace = frontendParams?.minLivingSpace ?? frontendParams?.min_living_space ?? undefined;
    maxSpace = frontendParams?.maxLivingSpace ?? frontendParams?.max_living_space ?? undefined;
  }
  if (singleFilterConfig?.fields?.includes(RawFilterConfigCriteria.PROPERTY_SIZE)) {
    minSpace = frontendParams?.minPropertySize ?? frontendParams?.min_property_size ?? undefined;
    maxSpace = frontendParams?.maxPropertySize ?? frontendParams?.max_property_size ?? undefined;
  }
  if (singleFilterConfig?.fields?.includes(RawFilterConfigCriteria.TOTAL_SPACE)) {
    minSpace = frontendParams?.minTotalSpace ?? frontendParams?.min_total_space ?? undefined;
    maxSpace = frontendParams?.maxTotalSpace ?? frontendParams?.max_total_sSpace ?? undefined;
  }

  let minRooms = undefined;
  let maxRooms = undefined;
  if (singleFilterConfig?.fields?.includes(RawFilterConfigCriteria.ROOMS)) {
    minRooms = frontendParams?.minNumberRooms ?? frontendParams?.min_number_rooms ?? undefined;
    maxRooms = frontendParams?.maxNumberRooms ?? undefined;
  }

  let sort = undefined;
  let page = undefined;
  let pageSize = undefined;
  if (!isCount) {
    sort = frontendParams?.sortBy ?? frontendParams?.sort_by ?? undefined;
    sort = sort ? mapSortByToId(sort) : undefined;
    page = frontendParams?.page ?? 1;
    pageSize = frontendParams?.limit ?? 12;
  }

  const offerType = marketingType
    ? marketingType === MarketingType.BUY
      ? OfferType.SALES
      : OfferType.RENTAL
    : undefined;

  const offererGroupingCode = frontendParams?.regioClientId ?? frontendParams?.regio_client_id ?? undefined;

  const estateIds = frontendParams?.estateIds?.length > 0 ? frontendParams.estateIds.join(',') : undefined;

  return {
    regionalSearch,
    latitude,
    longitude,
    radius,
    minPrice,
    maxPrice,
    minRooms,
    maxRooms,
    minSpace,
    maxSpace,
    estateTypeIds,
    sort,
    page,
    pageSize,
    offerType,
    offererGroupingCode,
    estateIds,
  };
};

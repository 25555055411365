import { Autocomplete as MuiAutocomplete, Box, SxProps, TextFieldProps } from '@mui/material';
import { SelectFieldItem } from '@portals/core/src/components/SelectFieldItem/SelectFieldItem';
import { ApiClientProvider } from '@portals/sip-client-data/src/general/ApiClient';
import { getFixedT } from 'i18next';
import { debounce, isEqual, sortBy, trim } from 'lodash-es';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { if6CssPrefix, maxZipCityEstateIds } from '../../../../config';
import { ImmobilienApiSwitchContext } from '../../../../context';
import { theme } from '../../../../themes/main';
import { Logger } from '../../../../utils';
import { EstateSearchAutocompleteInput } from './EstateSearchAutocompleteInput/EstateSearchAutocompleteInput';
import { EstateSearchAutocompletePopper } from './EstateSearchAutocompletePopper/EstateSearchAutocompletePopper';

interface Option {
  value: number;
  label: string;
}

export interface Props {
  preSelectedOptions?: Option[];
  onSelectedOptions: (selectedOptions: Option[]) => void;
  textFieldProps?: TextFieldProps;
  onNothingSelected?: (searchIsEmpty: boolean) => void;
}

const t = getFixedT(null, 'core-immobilien');

const styles: Record<string, SxProps> = {
  inputRoot: {
    '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
      pl: '14px',
      '& input': {
        pl: theme.spacing(5),
      },
    },
  },
  selectFieldItem: {
    p: { lg: 6 },
  },
  selectFieldItemBox: {
    p: theme.spacing(4),
  },
};

export const EstateSearchAutocomplete: React.FC<Props> = ({
  preSelectedOptions,
  onSelectedOptions,
  textFieldProps,
  onNothingSelected,
}: Props) => {
  // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: switch and context
  const { useImmobilienApi } = useContext(ImmobilienApiSwitchContext);

  const [selectedOptions, setSelectedOptions] = useState(preSelectedOptions ? preSelectedOptions : []);
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isEqual(sortBy(preSelectedOptions), sortBy(selectedOptions))) {
      onSelectedOptions(selectedOptions);
    }
  }, [selectedOptions, onSelectedOptions]);

  const fetchCities = async (term: string) => {
    const trimmedTerm = trim(term);
    if (!trimmedTerm) {
      setOptions([]);
      return;
    }

    try {
      const citiesResponse = await ApiClientProvider.getApiClient().getAutoCompleteOptions(trimmedTerm);
      setOptions(citiesResponse);
    } catch (e) {
      Logger.error(e);
    }
  };

  const debounceFetch = useRef(debounce((term) => fetchCities(term), 300)).current;

  useEffect(() => {
    debounceFetch(value);
  }, [value, debounceFetch]);

  const handleOnBlur = useCallback(() => {
    if (selectedOptions.length === 0) {
      onNothingSelected(value === '');
    }
  }, [onNothingSelected, selectedOptions.length, value]);

  const handleOnChange = useCallback(
    (event, newValue, reason) => {
      if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Backspace' && reason === 'removeOption') {
        return;
      }
      if ((event as React.KeyboardEvent).key === 'Enter' && reason === 'createOption') {
        return;
      }
      if (newValue.length > maxZipCityEstateIds) {
        enqueueSnackbar(t('whereOptionsMaximum'));
        return;
      }

      // TODO: REMOVE AFTER IMMOBILIEN API INTEGRATION: always use filter without switch
      if (useImmobilienApi && newValue?.length > 1) {
        setSelectedOptions([newValue.at(-1)]);
        setValue(newValue.at(-1).label);
      } else {
        setSelectedOptions(newValue);
      }
    },
    [enqueueSnackbar, useImmobilienApi]
  );

  const getOptionLabel = useCallback((option) => option.label, []);

  const isOptionEqualToValue = useCallback((option, selectedOption) => option.value === selectedOption.value, []);

  const renderPopperList = useCallback(
    (params, option, state) => (
      <SelectFieldItem
        key={option.value}
        id={String(option.value)}
        className={`autocomplete-list-item ${state.selected ? 'active' : ''}`}
        {...params}
        sx={styles.selectFieldItem}
      >
        <Box sx={styles.selectFieldItemBox}>{option.label}</Box>
      </SelectFieldItem>
    ),
    []
  );

  const renderInput = useCallback(
    (params) => (
      <EstateSearchAutocompleteInput
        params={params}
        setValue={setValue}
        value={value}
        textFieldProps={textFieldProps}
        onBlur={handleOnBlur}
      />
    ),
    [handleOnBlur, textFieldProps, value]
  );

  return (
    <MuiAutocomplete
      freeSolo
      data-testid="zipCityEstateIdAutocomplete"
      fullWidth
      multiple
      inputValue={value}
      renderOption={renderPopperList}
      value={selectedOptions}
      options={options ? options : []}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      onChange={handleOnChange}
      isOptionEqualToValue={isOptionEqualToValue}
      slots={{
        popper: (props) => <EstateSearchAutocompletePopper {...props} inputValue={value} />,
      }}
      slotProps={{
        listbox: { className: 'city-autocomplete' },
        paper: { className: if6CssPrefix },
      }}
      sx={styles.inputRoot}
    />
  );
};

EstateSearchAutocomplete.displayName = 'EstateSearchAutocomplete';

import { Menu, SvgIconProps } from '@mui/material';
import { IconButton } from '@portals/core/src/components/IconButton/IconButton';
import { TextField } from '@portals/core/src/components/TextField/TextField';
import { ChevronDownSmall } from '@portals/icons';
import { ChevronUpSmall } from '@portals/icons/src/ChevronUpSmall/ChevronUpSmall';
import React, { useCallback, useState } from 'react';

import { if6CssPrefix } from '../../../config';
import { usePreventSafariAutozoom } from '../../../hooks';
import { ButtonGroupSelect, Props as ButtonGroupSelectProps } from '../ButtonGroupSelect/ButtonGroupSelect';
import { styles } from './ButtonGroupSelectTextField.styles';
interface Props extends ButtonGroupSelectProps {
  InputAdornmentIcon: React.FC<SvgIconProps>;
  label: string;
  inputValue: string;
  onCloseHandler?: () => void;
  menuMaxHeight?: number;
}

export const ButtonGroupSelectTextField: React.FC<Props> = ({
  InputAdornmentIcon,
  label,
  inputValue,
  buttonOptions,
  selectOptions,
  buttonValue,
  selectValue,
  highlightSelectedOption,
  onButtonClick,
  onSelectClick,
  onCloseHandler,
  menuMaxHeight,
}: Props) => {
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [menuWidth, setMenuWidth] = useState(0);

  const preventSafariAutozoom = usePreventSafariAutozoom();

  const shouldMenuClose = (shouldClose: boolean): boolean => {
    if (shouldClose) {
      setMenuAnchorElement(null);
    }

    return shouldClose;
  };

  const onTextFieldClick = useCallback((event) => {
    setMenuWidth(event.currentTarget.offsetWidth);
    setMenuAnchorElement(event.currentTarget);
  }, []);

  const onMenuClose = useCallback(() => {
    onCloseHandler();
    setMenuAnchorElement(null);
  }, [onCloseHandler]);

  const onButtonGroupSelectButtonClick = useCallback(
    (value) => {
      return shouldMenuClose(onButtonClick(value));
    },
    [onButtonClick]
  );

  const onButtonGroupSelectSelectClick = useCallback(
    (value) => {
      return shouldMenuClose(onSelectClick(value));
    },
    [onSelectClick]
  );

  return (
    <>
      <TextField
        className="button-group-select-textfield"
        fullWidth
        label={inputValue !== '' ? label : ''}
        variant="outlined"
        value={inputValue !== '' ? inputValue : label}
        sx={styles.textField}
        slotProps={{
          input: {
            readOnly: true,
            startAdornment: <InputAdornmentIcon />,
            endAdornment: (
              <IconButton sx={styles.textFieldIconButton}>
                {menuAnchorElement ? <ChevronUpSmall /> : <ChevronDownSmall />}
              </IconButton>
            ),
          },
        }}
        onClick={onTextFieldClick}
      />
      <Menu
        className={if6CssPrefix}
        disableScrollLock={true}
        marginThreshold={null}
        MenuListProps={{
          className: 'estate-custom-select-menu-list',
          sx: {
            width: menuWidth,
            paddingTop: 0,
            paddingBottom: 0,
            maxHeight: menuMaxHeight ?? undefined,
            overflow: menuMaxHeight ? 'auto' : undefined,
          },
        }}
        anchorEl={menuAnchorElement}
        open={!!menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...preventSafariAutozoom}
      >
        <ButtonGroupSelect
          buttonOptions={buttonOptions}
          selectOptions={selectOptions}
          buttonValue={buttonValue}
          selectValue={selectValue}
          highlightSelectedOption={highlightSelectedOption}
          onButtonClick={onButtonGroupSelectButtonClick}
          onSelectClick={onButtonGroupSelectSelectClick}
        />
      </Menu>
    </>
  );
};

ButtonGroupSelectTextField.displayName = 'ButtonGroupSelectTextField';

import { SelectChangeEvent, SelectProps, SxProps } from '@mui/material';
import { SelectField } from '@portals/core/src/components/SelectField/SelectField';
import { SelectFieldItem } from '@portals/core/src/components/SelectFieldItem/SelectFieldItem';
import { getFixedT } from 'i18next';
import React, { FocusEventHandler, useCallback, useState } from 'react';

import { if6CssPrefix } from '../../../../../config';
import { usePreventSafariAutozoom } from '../../../../../hooks';

type Props = {
  name: string;
  label: string;
  value: string | number;
  renderValue: (value: string | number) => string | number;
  items: Array<{ value: string | number; label: string }>;
  onChange: (any) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  error?: boolean;
  errorMessage?: string;
} & Omit<SelectProps, 'name' | 'label' | 'value' | 'onChange' | 'onBlur' | 'error' | 'sx' | 'MenuProps' | 'ref'>;

const t = getFixedT(null, 'core-immobilien');

const styles: Record<string, SxProps> = {
  selectFieldItem: {
    p: { lg: 6 },
  },
};

export const EstateSearchFilterSelectInput = ({
  name,
  label,
  value,
  renderValue,
  items,
  onChange,
  onBlur,
  error = false,
  errorMessage = '',
  ...props
}: Props): React.ReactElement => {
  const preventSafariAutozoom = usePreventSafariAutozoom();
  const [selectedValue, setSelectedValue] = useState(value ? value : '');

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const changedValue = event.target.value as string | number;
      if (changedValue !== '') {
        setSelectedValue(changedValue);
        onChange(changedValue);
      }
    },
    [onChange]
  );

  return (
    <SelectField
      id={name}
      label={label}
      className="estate-select"
      name={name}
      fullWidth
      variant="outlined"
      value={selectedValue ?? t('estateSearch.any')}
      onChange={handleChange}
      onBlur={onBlur}
      error={error}
      helperText={error ? errorMessage : ''}
      renderValue={renderValue}
      MenuProps={{
        classes: {
          paper: `${if6CssPrefix} estate-select-list`,
        },
      }}
      {...preventSafariAutozoom}
      {...props}
    >
      {items?.map((item) => (
        <SelectFieldItem
          key={item.value}
          value={item.value}
          id={`estate-search-filter-item_${name}_${item.value}`}
          type="radio"
          checked={selectedValue === item.value}
          sx={styles.selectFieldItem}
        >
          {item.label}
        </SelectFieldItem>
      ))}
    </SelectField>
  );
};

EstateSearchFilterSelectInput.displayName = 'EstateSearchFilterSelectInput';

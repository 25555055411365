import { FormControl, TextFieldProps } from '@mui/material';
import { TextField } from '@portals/core/src/components/TextField/TextField';
import React, { ChangeEventHandler, FocusEventHandler } from 'react';

import { usePreventSafariAutozoom } from '../../../../../hooks';

type Props = {
  name: string;
  label: string;
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  error?: boolean;
  errorMessage?: string | undefined;
} & Omit<TextFieldProps, 'name' | 'label' | 'value' | 'onChange' | 'onBlur' | 'error' | 'ref'>;

export const EstateSearchFilterTextInput = ({
  name,
  label,
  value = '',
  onChange,
  onBlur,
  error = false,
  errorMessage = '',
  ...props
}: Props): React.ReactElement => {
  const preventSafariAutozoom = usePreventSafariAutozoom();

  return (
    <FormControl fullWidth>
      <TextField
        name={name}
        className="estate-input"
        label={`${value}` !== '' ? label : null}
        placeholder={`${value}` === '' && label}
        fullWidth
        variant="outlined"
        value={value ? `${value}` : ''}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={error ? errorMessage : ''}
        {...preventSafariAutozoom}
        {...props}
      />
    </FormControl>
  );
};

EstateSearchFilterTextInput.displayName = 'EstateSearchFilterTextInput';

import { IF6Config } from '../../../if6/IF6Config';
import { ConfigProvider } from '../../Config';
import { mapSearchParamsToApiParams } from './MapSearchParamsToApiParams';

export const mapIF6SearchParamsToApiParams = (frontendParams: any, isCount = false) => {
  const regioClientId = (ConfigProvider.getConfig() as IF6Config)?.get('BANK_CODE');
  const mappedParams = mapSearchParamsToApiParams({ ...frontendParams, regioClientId }, isCount);
  const stringifiedParams = Object.fromEntries(
    Object.entries(mappedParams)
      .filter(([, value]) => value != null)
      .map(([key, value]) => [key, String(value)])
  );
  const params = new URLSearchParams(stringifiedParams).toString();
  return {
    route: `immobilien-api/estates${isCount ? '/count' : ''}${params?.length > 0 ? `?${params}` : ''}`,
    return_data: 'react_frontend',
  };
};

import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SelectFieldItem } from '@portals/core/src/components/SelectFieldItem/SelectFieldItem';
import { map } from 'lodash-es';
import React, { useCallback } from 'react';

import { styles } from './ButtonGroupSelect.styles';

export interface ButtonOptionsProps {
  value: unknown;
  label: string;
}

export interface SelectOptionsProps {
  value: unknown;
  label: string;
}

export interface Props {
  buttonOptions: ButtonOptionsProps[];
  selectOptions: SelectOptionsProps[];
  buttonValue: unknown;
  selectValue: unknown;
  highlightSelectedOption: boolean;
  onButtonClick?: (value: unknown) => boolean;
  onSelectClick?: (value: unknown) => boolean;
}

export const ButtonGroupSelect: React.FunctionComponent<Props> = ({
  buttonOptions,
  selectOptions,
  buttonValue,
  selectValue,
  onButtonClick = () => false,
  onSelectClick = () => false,
}: Props) => {
  const handleToggleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: unknown) => {
      if (value !== null) {
        onButtonClick(value);
      }
    },
    [onButtonClick]
  );

  const handleSelectClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: unknown) => {
      onSelectClick(value);
    },
    [onSelectClick]
  );

  const handleSelectClickWithValue = (value: unknown) => (event: React.MouseEvent<HTMLElement>) => {
    handleSelectClick(event, value);
  };

  return (
    <>
      <Box sx={styles.buttonGroupWrapper}>
        <ToggleButtonGroup
          exclusive
          fullWidth
          sx={styles.toggleButtonGroup}
          value={buttonValue}
          onChange={handleToggleButtonClick}
        >
          {map(buttonOptions, (option) => (
            <ToggleButton disableRipple key={option.value} value={option.value} sx={styles.toggleButton}>
              {option.label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      {map(selectOptions, (option) => (
        <SelectFieldItem
          id={option.value}
          key={option.value}
          onClick={handleSelectClickWithValue(option.value)}
          type="radio"
          checked={selectValue === option.value}
          disableRipple
          sx={styles.selectFieldItem}
        >
          {option.label}
        </SelectFieldItem>
      ))}
    </>
  );
};

ButtonGroupSelect.displayName = 'ButtonGroupSelect';

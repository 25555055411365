import { IF6Config } from '../../../if6/IF6Config';
import { ConfigProvider } from '../../Config';
import { getLabelsFromZipCityEstateId } from '../ApiClientMapping';
import { apiFrontendMapping as _apiFrontendMapping } from '../ApiFrontendMapping';

export const mapIF6SearchParamsToApiParams = (frontendParams: any) => {
  const regioClientId = (ConfigProvider.getConfig() as IF6Config)?.get('BANK_CODE');
  frontendParams = { ...frontendParams, regioClientId };

  const sipApiParams = {
    route: 'estate',
    return_data: 'teaser_list',
  };

  for (const frontendKey in _apiFrontendMapping) {
    if (typeof frontendParams[frontendKey] !== 'undefined') {
      const apiKey = _apiFrontendMapping[frontendKey];
      sipApiParams[apiKey] = frontendParams[frontendKey];
    }
  }

  sipApiParams['zip_city_estate_id'] = getLabelsFromZipCityEstateId(frontendParams['zipCityEstateId']);
  sipApiParams['estate_type'] = sipApiParams['object_type'];
  delete sipApiParams['object_type'];

  return sipApiParams;
};

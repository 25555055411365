import { Box, ToggleButton as MUIToggleButton, toggleButtonClasses, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import { LoadingDots } from '@portals/core/src/components/LoadingDots/LoadingDots';
import i18next from 'i18next';
import React from 'react';

import { ResultListTypes } from '../../../config';
import { DottedList, MapMagnifier } from '../../../icons';

interface EstateResultTypeToggleProps {
  value?: ResultListTypes;
  onChange: ToggleButtonGroupProps['onChange'];
  isStickyHeader: boolean;
  isLoading?: boolean;
}

const ToggleButton = styled(MUIToggleButton)(({ theme }) => ({
  borderRadius: '90px',
  border: 'none !important', //todo: sorry for that - doch s.de theme benutzt keinen border, sondern boxShadow....
  boxShadow: `inset 0px 0px 0px 1px ${theme.palette.secondary['main']}`,
  paddingTop: theme.spacing(5),
  paddingRight: theme.spacing(6),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(6),
  margin: 0,
  fontSize: 16,
  [theme.breakpoints.down('lg')]: {
    fontSize: 13,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(5),
  },
  '&:hover': {
    color: '#fff',
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[800]}`,
    backgroundColor: theme.palette.grey[800],
  },
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.primary.contrastText,
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.green.main}`,
  },
  [`&.${toggleButtonClasses.selected}:hover`]: {
    backgroundColor: theme.palette.grey[800],
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.grey[800]}`,
    color: theme.palette.primary.contrastText,
  },
  '& .MuiSvgIcon-fontSizeMedium': {
    fontSize: 20,
  },
  '&.MuiToggleButtonGroup-lastButton': {
    marginLeft: 0,
  },
}));

const t = i18next.getFixedT.bind(i18next)(null, 'core-immobilien');

export const EstateResultTypeToggle: React.FC<EstateResultTypeToggleProps> = ({
  value,
  onChange,
  isStickyHeader,
  isLoading = false,
}: EstateResultTypeToggleProps) => {
  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={onChange}
      fullWidth
      sx={{ width: { xs: isStickyHeader ? 'auto' : '100%', sm: 'auto' } }}
    >
      <ToggleButton disableRipple value={ResultListTypes.LIST}>
        <Box display="flex" alignItems="center">
          <Box pr={3} alignItems="center" sx={{ display: { xs: isStickyHeader ? 'none' : 'flex', sm: 'flex' } }}>
            <DottedList />
          </Box>
          <Box sx={{ minHeight: '20px' }} display="inherit">
            {t('resultType.list')}
          </Box>
        </Box>
      </ToggleButton>
      <ToggleButton disableRipple value={ResultListTypes.MAP}>
        <Box display="flex" alignItems="center">
          <Box pr={3} alignItems="center" sx={{ display: { xs: isStickyHeader ? 'none' : 'flex', sm: 'flex' } }}>
            <MapMagnifier />
          </Box>
          {!isLoading && (
            <Box sx={{ minHeight: '20px' }} display="inherit">
              {t('resultType.card')}
            </Box>
          )}
          {isLoading && <LoadingDots />}
        </Box>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

EstateResultTypeToggle.displayName = 'EstateResultTypeToggle';

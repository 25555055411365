import { SelectChangeEvent, SelectProps, SxProps } from '@mui/material';
import { SelectField } from '@portals/core/src/components/SelectField/SelectField';
import { SelectFieldItem } from '@portals/core/src/components/SelectFieldItem/SelectFieldItem';
import { Target } from '@portals/icons';
import { getFixedT } from 'i18next';
import React, { useCallback, useState } from 'react';

import { if6CssPrefix } from '../../../../config';

type Props = {
  items: any;
  onChange: (any) => void;
  formatValue: (any) => string | boolean;
  label?: string;
  initialValue?: string | number;
  testId?: string;
} & Omit<SelectProps, 'onChange' | 'label'>;

const t = getFixedT(null, 'core-immobilien');

const styles: Record<string, SxProps> = {
  selectFieldItem: {
    p: { lg: 6 },
  },
  selectField: {
    borderTopLeftRadius: { sm: '0' },
    borderBottomLeftRadius: { sm: '0' },
    flexGrow: 1,
    ml: { sm: '-1px' },
  },
};

export const EstateSearchSelect: React.FC<Props> = ({
  items,
  onChange,
  formatValue,
  label,
  initialValue,
  testId,
  ...props
}: Props) => {
  const [selectedValue, setSelectedValue] = useState(initialValue ? initialValue : '');

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as string | number;
      if (value !== '') {
        setSelectedValue(value);
        onChange(value);
      }
    },
    [onChange]
  );

  const checkIfTypeIsOther = useCallback(
    (value) => {
      return value === Number.MAX_VALUE ? t('estateSearch.any') : formatValue(value);
    },
    [formatValue]
  );

  return (
    <SelectField
      data-testid={testId}
      id={testId}
      className="estate-custom-select"
      variant="standard"
      label={label}
      labelId={`estate-search-extended-filter-label_${label}`}
      value={selectedValue}
      onChange={handleChange}
      MenuProps={{
        className: if6CssPrefix,
        MenuListProps: { className: 'estate-custom-select-menu-list' },
      }}
      renderValue={checkIfTypeIsOther}
      startAdornment={<Target />}
      {...props}
      sx={{ ...props.sx, ...styles.selectField } as SxProps}
    >
      {items.map((item) => (
        <SelectFieldItem
          id={`estate-search-extended-filter-item_${item.value}`}
          key={item.value}
          value={item.value}
          checked={selectedValue === item.value}
          type="radio"
          className="estate-custom-select-menu-list"
          sx={styles.selectFieldItem}
        >
          {item.label}
        </SelectFieldItem>
      ))}
    </SelectField>
  );
};

EstateSearchSelect.displayName = 'EstateSearchSelect';

import { Box } from '@mui/material';
import { EstateItem } from '@portals/sip-client-data/src/general/ApiClientTypes';
import React from 'react';

import { useIsBookmarked } from '../../../../hooks';
import { Heart } from '../../../../icons';
import { EstateMarker } from './EstateMarker';

export const EstateMarkerComponent: React.FC<{
  estateItem: EstateItem;
  onClickEstateMarker: (event: L.LeafletMouseEvent, estateID?: string) => void;
}> = ({ estateItem, onClickEstateMarker }) => {
  const { isBookmarked } = useIsBookmarked(estateItem.id);

  return (
    <EstateMarker
      key={estateItem.id}
      estateId={estateItem.id}
      position={{
        lat: estateItem.lat,
        lng: estateItem.lng,
      }}
      onClick={(event) => onClickEstateMarker(event, estateItem.id)}
    >
      <Box className={isBookmarked ? 'bookmarked' : ''}>
        {isBookmarked && (
          <Box className="icon-bookmarked">
            <Heart fontSize="inherit" color="primary" />
          </Box>
        )}
        {estateItem.price}
      </Box>
    </EstateMarker>
  );
};

EstateMarkerComponent.displayName = 'EstateMarkerComponent';
